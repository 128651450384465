.audioRange_volume {
  width: 50%;
  max-width: 160px;
}

.audioRange_time {
  width: 100%;
}

.audioRange {
  font-size: 1.5rem;
  color: #f07167;
  --track-color: rgba(255, 255, 255, 0.2);
  --thumb-height: 0.5em;
  --track-height: 0.125em;
  --brightness-hover: 120%;
  --brightness-down: 80%;
  --clip-edges: 0.125em;
  position: relative;
  background: #fff0;
  overflow: hidden;
  cursor: grabbing;
}

/* === WebKit specific styles === */
.audioRange,
.audioRange::-webkit-slider-runnable-track,
.audioRange::-webkit-slider-thumb {
  -webkit-appearance: none;
  transition: all ease 100ms;
  height: var(--thumb-height);
}

.audioRange::-webkit-slider-runnable-track,
.audioRange::-webkit-slider-thumb {
  position: relative;
}

.audioRange::-webkit-slider-thumb {
  --thumb-radius: calc((var(--thumb-height) * 0.5) - 1px);
  --clip-top: calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px);
  --clip-bottom: calc(var(--thumb-height) - var(--clip-top));
  --clip-further: calc(100% + 1px);
  --box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0 100vmax currentColor;

  width: var(--thumb-width, var(--thumb-height));
  background: linear-gradient(currentColor 0 0) scroll no-repeat left center / 50% calc(var(--track-height) + 1px);
  background-color: currentColor;
  box-shadow: var(--box-fill);
  border-radius: var(--thumb-width, var(--thumb-height));

  filter: brightness(100%);
  clip-path: polygon(
    100% -1px,
    var(--clip-edges) -1px,
    0 var(--clip-top),
    -100vmax var(--clip-top),
    -100vmax var(--clip-bottom),
    0 var(--clip-bottom),
    var(--clip-edges) 100%,
    var(--clip-further) var(--clip-further)
  );
}

.audioRange:hover::-webkit-slider-thumb {
  filter: brightness(var(--brightness-hover));
  cursor: grab;
}

.audioRange:active::-webkit-slider-thumb {
  filter: brightness(var(--brightness-down));
  cursor: grabbing;
}

.audioRange::-webkit-slider-runnable-track {
  background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center / 100% calc(var(--track-height) + 1px);
}

.audioRange:disabled::-webkit-slider-thumb {
  cursor: not-allowed;
}

/* === Firefox specific styles === */
.audioRange,
.audioRange::-moz-range-track,
.audioRange::-moz-range-thumb {
  appearance: none;
  transition: all ease 100ms;
  height: var(--thumb-height);
}

.audioRange::-moz-range-track,
.audioRange::-moz-range-thumb,
.audioRange::-moz-range-progress {
  background: #fff0;
}

.audioRange::-moz-range-thumb {
  background: currentColor;
  border: 0;
  width: var(--thumb-width, var(--thumb-height));
  border-radius: var(--thumb-width, var(--thumb-height));
  cursor: grab;
}

.audioRange:active::-moz-range-thumb {
  cursor: grabbing;
}

.audioRange::-moz-range-track {
  width: 100%;
  background: var(--track-color);
}

.audioRange::-moz-range-progress {
  appearance: none;
  background: currentColor;
  transition-delay: 30ms;
}

.audioRange::-moz-range-track,
.audioRange::-moz-range-progress {
  height: calc(var(--track-height) + 1px);
  border-radius: var(--track-height);
}

.audioRange::-moz-range-thumb,
.audioRange::-moz-range-progress {
  filter: brightness(100%);
}

.audioRange:hover::-moz-range-thumb,
.audioRange:hover::-moz-range-progress {
  filter: brightness(var(--brightness-hover));
}

.audioRange:active::-moz-range-thumb,
.audioRange:active::-moz-range-progress {
  filter: brightness(var(--brightness-down));
}

.audioRange:disabled::-moz-range-thumb {
  cursor: not-allowed;
}
