@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Raleway:wght@300;400;600&family=Roboto+Mono:wght@100;200;300;400;500;600;700&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  overflow-x: auto;
}

.notifications {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1000;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
